exports.timePeriodOptions = [
  {
    label: "Today",
    value: "today",
  },
  {
    label: "Yesterday",
    value: "yesterday",
  },
  {
    label: "Last 7 days",
    value: "7days",
  },
  {
    label: "Last 30 days",
    value: "30days",
  },
  {
    label: "This month",
    value: "thisMonth",
  },
  {
    label: "Custom",
    value: "custom",
  },
];

exports.reportOptions = [
  {
    label: "Select",
    value: "",
  },
  {
    label: "Orders",
    value: "orders",
  },
  {
    label: "Abandoned Checkouts",
    value: "abandoned_checkouts",
  },
  {
    label: "Settlements",
    value: "settlements",
  },
  {
    label: "UTM Analytics",
    value: "utm_analytics",
  },
  {
    label: "Reconciliation Report",
    value: "recon_report",
  },
  {
    label: "Abandoned Recovery Report",
    value: "abc_recovered",
  },
  {
    label: "Coupon Analytics Report",
    value: "coupon_analytics",
  },
];

exports.discountTypeOptions = [
  {
    label: "Flat",
    value: "flat",
  },
  {
    label: "Percentage",
    value: "percent",
  },
];

exports.aggregatorOptions = [
  { label: "All", value: "select" },
  {
    label: "Campaign Code",
    value: "campaign",
  },
  {
    label: "Campaign Source",
    value: "source",
  },
  {
    label: "Campaign Medium",
    value: "medium",
  },
  {
    label: "Referrer",
    value: "referrer",
  },
];

exports.timeFormatOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
};

exports.tabPermissionMapper = {
  Analytics: ["analytics-overview", "coupon-analytics", "utm-analytics"],
  // "Abandoned Carts": ["abc-wa-config", "abc-sms-config"],
  "Abandoned Checkouts": [
    "abandoned-checkouts",
    "abandoned-checkouts-via-number",
    "abc-sms-config",
    "converted-orders",
  ],
  Orders: ["orders"],
  Payments: ["transactions", "settlements", "refunds"],
  Discounts: ["discounts"],
  Reports: ["reports"],
  Settings: [
    "cod",
    "prepaid",
    "partial-payments",
    "event-tracking",
    "checkout-form",
    "whatsapp-config",
    "sms-config",
    "shipping-providers",
    "express-shipping",
    "business-info",
    "abc-negative-skus",
  ],
  Wallet: ["wallet"],
  // Support: ["support"],
  Users: ["users"],
  CODFIRM: ["CODFIRM"],
};

exports.permissionNameMapper = {
  "analytics-overview": "Analytics Overview",
  "abc-sms-config": "Recover via SMS",
  "abc-negative-skus": "Create Cart Customization",
  "converted-orders": "Converted Orders",
  "abc-wa-config": "Recover via WhatsApp",
  "coupon-analytics": "Coupon Analytics",
  "utm-analytics": "UTM Analytics",
  orders: "Orders",
  "edit-orders": "Edit Orders",
  "abandoned-checkouts": "All Abandoned Checkouts",
  "abandoned-checkouts-via-number": "Abandoned Checkouts via number",
  transactions: "Transactions",
  settlements: "Settlements",
  refunds: "Refunds",
  discounts: "Discounts",
  reports: "Reports",
  // "payment-method": "Payment Method",
  cod: "Payment Method - COD",
  prepaid: "Payment Method - Prepaid",
  "partial-payments": "Payment Method - Partial Payments",
  "event-tracking": "Event Tracking",
  "checkout-form": "Checkout Form",
  // support: "Support",
  users: "Users",
  prepaid: "Prepaid Config",
  cod: "COD Config",
  "whatsapp-config": "WhatsApp Config",
  "sms-config": "SMS Config",
  "shipping-providers": "Shipping Providers",
  "express-shipping": "Express Shipping",
  "business-info": "Business Info",
  wallet: "Wallet",
  "create-cart": "Create Cart",
};

exports.availaleUserPermissions = {
  "analytics-overview": false,
  "coupon-analytics": false,
  "utm-analytics": false,
  "abandoned-checkouts": false,
  "abandoned-checkouts-via-number": false,
  "abc-sms-config": false,
  "abc-negative-skus": false,
  "abc-wa-config": false,
  orders: false,
  "edit-orders": false,
  transactions: false,
  settlements: false,
  refunds: false,
  discounts: false,
  reports: false,
  cod: false,
  prepaid: false,
  "partial-payments": false,
  "event-tracking": false,
  "checkout-form": false,
  "whatsapp-config": false,
  "sms-config": false,
  wallet: false,
  // support: false,
  users: false,
  "shipping-providers": false,
  "express-shipping": false,
  "business-info": false,
  "converted-orders": false,
  "create-cart": false,
};

exports.tableUtmHeader = [
  { label: "Date/ Time", key: "date" },
  { label: "Initiated Checkout(IC)", key: "ic" },
  { label: "OTP Verified(VF)", key: "ov" },
  { label: "Reached Payment Page (RPP)", key: "rpp" },
  { label: "Sessions Converted (SC)", key: "sc" },
  { label: "Total Order Value", key: "total" },
];

exports.tableUtmHeaderFilter = [
  { label: "Date/ Time", key: "date" },
  { label: "OTP Verified(VF)", key: "ov" },
  { label: "Reached Payment Page (RPP)", key: "rpp" },
  { label: "Sessions Converted (SC)", key: "sc" },
  { label: "Total Order Value", key: "total" },
];

exports.transactionStatusMapper = {
  PAYMENT_SUCCESSFUL: "Payment Successful",
  SUCCESS: "Payment Successful",
  SETTLEMENT_SUCCESSFUL: "Settlement Successful",
  Initiated: "Refund Initiated",
  MarkedForRefund: "Marked For Refund",
};

exports.transactionBadgeMapper = {
  PAYMENT_SUCCESSFUL: "info",
  SETTLEMENT_SUCCESSFUL: "success",
  SUCCESS: "info",
};

exports.whatsappProviderOptions = [
  {
    label: "Select",
    value: "",
  },
  {
    label: "Gupshup",
    value: "gupshup",
  },
  {
    label: "Gupshup Enterprise",
    value: "gupshup_enterprise",
  },
  {
    label: "Netcore",
    value: "netcore",
  },
  {
    label: "Zoko",
    value: "zoko",
  },
  {
    label: "Interakt",
    value: "interakt",
  },
  {
    label: "Tellephant",
    value: "tellephant",
  },
  { label: "Limechat", value: "limechat" },
];

exports.providerOptionsMapper = {
  gupshup: [
    { key: "appId", label: "App ID" },
    { key: "apiKey", label: "Api Key" },
    { key: "source", label: "Source" },
  ],
  gupshup_enterprise: [
    { key: "hsmId", label: "HSM ID" },
    { key: "hsmPassword", label: "HSM Password" },
  ],
  interakt: [{ key: "apiKey", label: "Api Key" }],
  netcore: [
    { key: "apiKey", label: "Api Key" },
    { key: "source", label: "Source" },
  ],
  tellephant: [{ key: "apiKey", label: "Api Key" }],
  zoko: [{ key: "apiKey", label: "Api Key" }],
  limechat: [
    { key: "accessToken", label: "Limechat UAT Token" },
    { key: "accountId", label: "Account ID" },
  ],
};

exports.whatsappTemplateInfoMapper = {
  gupshup: [
    { key: "enabled", label: "Status", type: "boolean" },
    { key: "delay", label: "Send after (in hours)", type: "number" },
    { key: "id", label: "Template ID", type: "text" },
    { key: "name", label: "Template Name", type: "text" },
    { key: "params", label: "Template Params", type: "array" },
    { key: "contact", label: "Contact", type: "text" },
  ],
  gupshup_enterprise: [
    { key: "enabled", label: "Status", type: "boolean" },
    { key: "delay", label: "Send after (in hours)", type: "number" },
    { key: "name", label: "Name", type: "text" },
    { key: "template", label: "Template", type: "text-large" },
    { key: "params", label: "Template Params", type: "array" },
    { key: "contact", label: "Contact", type: "text" },
  ],
  interakt: [
    { key: "enabled", label: "Status", type: "boolean" },
    { key: "delay", label: "Send after (in hours)", type: "number" },
    { key: "name", label: "Name", type: "text" },
    { key: "params", label: "Params", type: "array" },
    { key: "contact", label: "Contact", type: "text" },
  ],
  netcore: [
    { key: "enabled", label: "Status", type: "boolean" },
    { key: "delay", label: "Send after (in hours)", type: "number" },
    { key: "id", label: "Template ID" },
    { key: "name", label: "Template Name" },
    { key: "params", label: "Template Params", type: "array" },
    { key: "contact", label: "Contact", type: "text" },
  ],
  tellephant: [
    { key: "enabled", label: "Status", type: "boolean" },
    { key: "delay", label: "Send after (in hours)", type: "number" },
    { key: "id", label: "Template ID", type: "text" },
    { key: "name", label: "Template Name", type: "text" },
    { key: "params", label: "Template Params", type: "array" },
    { key: "contact", label: "Contact", type: "text" },
  ],
  zoko: [
    { key: "enabled", label: "Status", type: "boolean" },
    { key: "delay", label: "Send after (in hours)", type: "numer" },
    { key: "id", label: "Template ID", type: "text" },
    { key: "name", label: "Template Name", type: "text" },
    { key: "params", label: "Template Params", type: "array" },
    { key: "contact", label: "Contact", type: "text" },
  ],
  limechat: [
    { key: "enabled", label: "Status", type: "boolean" },
    { key: "delay", label: "Send after (in hours)", type: "numer" },
    { key: "lcEventType", label: "Limechat event name", type: "text" },
    { key: "name", label: "Template Name", type: "text" },
    { key: "params", label: "Template Params", type: "array" },
    { key: "contact", label: "Contact", type: "text" },
  ],
};

exports.smsTemplateInfo = [
  { key: "enabled", label: "Status", type: "boolean" },
  { key: "delay", label: "Send after (in hours)", type: "number" },
  { key: "dltTemplateId", label: "Template ID", type: "text" },
  { key: "name", label: "Name", type: "text" },
  { key: "dltTemplate", label: "Template Content", type: "text-large" },
  { key: "params", label: "Template Params", type: "array" },
  { key: "contact", label: "Contact", type: "text" },
];

exports.abandonedRecoveryParamOptions = [
  {
    value: "firstName",
    label: "firstName",
  },
  {
    value: "shopName",
    label: "shopName",
  },
  {
    value: "contact",
    label: "contact",
  },
  {
    value: "shortCode",
    label: "shortCode",
  },
  {
    value: "checkoutLink",
    label: "checkoutLink",
  },
];

exports.smsTemplate = {
  enabled: true,
  delay: 2,
  dltTemplateId: "1207166920041009276",
  name: "RAC-1",
  dltTemplate:
    "The items that you added to your ##shopName## cart are selling out fast. To complete your purchase, please visit ##checkoutLink## to checkout via Zecpe.",
  params: ["shopName", "checkoutLink"],
};

exports.waTemplate = {
  enabled: false,
  delay: 1,
  id: "",
  name: "",
  params: [],
};

exports.discountStatusOptions = [
  {
    label: "Enabled",
    value: true,
  },
  {
    label: "Disabled",
    value: false,
  },
];

// * Shipping Providers Array
exports.shippingProviders = [
  {
    label: "Bicree",
    value: "bicree",
  },
  {
    label: "Blue Dart",
    value: "bluedart",
  },
  {
    label: "Delhivery",
    value: "delhivery",
  },
  {
    label: "Clickpost",
    value: "clickpost",
  },
  {
    label: "iThink Logistics",
    value: "iThink",
  },
  {
    label: "Nimbus Post",
    value: "nimbusPost",
  },
  {
    label: "Pickrr",
    value: "pickrr",
  },
  {
    label: "Shiprocket",
    value: "shipRocket",
  },
  {
    label: "Shipway (Shipway.in)",
    value: "shipwayIn",
  },
  {
    label: "Shipway (Shipway.com)",
    value: "shipwayCom",
  },
  {
    label: "Ware IQ",
    value: "wareIq",
  },
  {
    label: "Xpressbees",
    value: "xpressBees",
  },
  {
    label: "Shippigo",
    value: "shippigo",
  },
  {
    label: "Shipyaari",
    value: "shipyaari",
  },
  {
    label: "Eshipz",
    value: "eshipz",
  },
];

// * Shipping Providers Options, Required Fields and Description
exports.shippingProviderOptions = {
  bicree: {
    requiredFields: [
      {
        label: "Username",
        value: "username",
      },
      {
        label: "Password",
        value: "password",
      },
    ],
    description: null,
  },
  bluedart: {
    requiredFields: [
      {
        label: "Login ID",
        value: "loginId",
      },
      {
        label: "License Key",
        value: "licenseKey",
      },
    ],
    description: null,
  },
  delhivery: {
    requiredFields: [
      {
        label: "API Token",
        value: "apiToken",
      },
    ],
    description: null,
  },
  clickpost: {
    requiredFields: [
      {
        label: "Username",
        value: "userName",
      },
      {
        label: "API Key",
        value: "apiKey",
      },
    ],
    description: null,
  },
  iThink: {
    requiredFields: [
      {
        label: "Access Token",
        value: "accessToken",
      },
      {
        label: "Secret Key",
        value: "secretKey",
      },
    ],
    description: null,
  },
  nimbusPost: {
    requiredFields: [
      {
        label: "Username",
        value: "userName",
      },
      {
        label: "Password",
        value: "password",
      },
    ],
    description: null,
  },
  pickrr: {
    requiredFields: [
      {
        label: "API Key",
        value: "apiKey",
      },
    ],
    description: null,
  },
  shipRocket: {
    requiredFields: [
      {
        label: "Username",
        value: "userName",
      },
      {
        label: "Password",
        value: "password",
      },
    ],
    description: `Please ensure that you use credentials created exclusively in the ShipRocket Dashboard. To access these credentials, follow the path: <strong>Settings > API > Configure</strong> section. You can find this setting <a href="https://app.shiprocket.in/api-user" target="_blank">here</a>.`,
  },
  shippigo: {
    requiredFields: [
      {
        label: "Username",
        value: "username",
      },
      {
        label: "Password",
        value: "password",
      },
    ],
  },
  shipwayIn: {
    requiredFields: [
      {
        label: "Username",
        value: "userName",
      },
      {
        label: "License Key",
        value: "password",
      },
    ],
    description: null,
  },
  shipwayCom: {
    requiredFields: [
      {
        label: "Username",
        value: "userName",
      },
      {
        label: "License Key",
        value: "password",
      },
    ],
    description: null,
  },
  wareIq: {
    requiredFields: [
      {
        label: "API Token",
        value: "apiToken",
      },
    ],
    description: null,
  },
  xpressBees: {
    requiredFields: [
      {
        label: "Username",
        value: "userName",
      },
      {
        label: "Password",
        value: "password",
      },
      {
        label: "Secret Key",
        value: "secretKey",
      },
    ],
    description: null,
  },
  shipyaari: {
    requiredFields: [
      { label: "Client ID", value: "clientId" },
      { label: "Parent ID", value: "parentId" },
    ],
    description: null,
  },
  eshipz: {
    requiredFields: [
      { label: "Email", value: "email" },
      { label: "Password", value: "password" },
    ],
    description: null,
  },
};
exports.walletAttributeMapper = {
  "abc-wa": "Abandoned checkout recovery whatsapp message",
  "abc-sms": "Abandoned checkout recovery SMS message",
  "wallet-recharge": "Wallet recharge",
  "payment-link-sms": "Payment link SMS",
  "payment-link-wa": "Payment link whatsapp",
};

exports.zecpeSmsConfig = {
  peId: "1207164982971624633",
  header: "iZecPe",
};

exports.defaultExpressShippingConfig = {
  enabled: false,
  fee: 0,
  normalShippingLabel: "",
  expressShippingLabel: "",
  showCodOption: false,
  limitExpShipByPincodes: false,
  useCustomRulesForShippingFee: false,
  rules: [],
};

exports.WhatsappTemplateParameters = {
  gupshup: [
    { key: "id", label: "Template ID (as on gupshup)", type: "text" },
    { key: "name", label: "Template Name", type: "text" },
    { key: "params", label: "Template Params", type: "array" },
  ],
  gupshup_enterprise: [
    { key: "name", label: "Name", type: "text" },
    { key: "template", label: "Template", type: "text-large" },
    { key: "params", label: "Template Params", type: "array" },
  ],
  interakt: [
    { key: "name", label: "Name", type: "text" },
    {
      key: "templateName",
      label: "Template Name (as on interakt)",
      type: "text",
    },
    { key: "languageCode", label: "Language Code", type: "text" },
    { key: "params", label: "Params", type: "array" },
  ],
  netcore: [
    { key: "id", label: "Template ID" },
    { key: "name", label: "Template Name" },
    { key: "params", label: "Template Params", type: "array" },
  ],
  tellephant: [
    { key: "id", label: "Template ID", type: "text" },
    { key: "name", label: "Template Name", type: "text" },
    { key: "params", label: "Template Params", type: "array" },
    {
      key: "languageCode",
      label: "Language Code",
      type: "select",
      options: [
        { label: "Select", value: "" },
        {
          label: "English",
          value: "en",
        },
      ],
    },
  ],
  zoko: [
    { key: "id", label: "Template ID (as on zoko)", type: "text" },
    { key: "name", label: "Template Name", type: "text" },
    { key: "templateType", label: "Template Type", type: "text" },
    { key: "params", label: "Template Params", type: "array" },
  ],
  limechat: [
    { key: "lcEventType", label: "Limechat event name", type: "text" },
    { key: "name", label: "Template Name", type: "text" },
    { key: "params", label: "Template Params", type: "array" },
  ],
};

exports.OtpTemplateParamOptions = [
  { label: "otp", value: "otp" },
  { label: "companyName", value: "companyName" },
];

exports.defaultBusinessInfoDetails = {
  registeredName: "",
  registeredAddress: "",
  gstin: "",
  billingEmail: "",
};

exports.utmNotification = {
  enabled: false,
  rppThreshold: 0,
  scThresholdOfIC: 0,
  scThresholdOfRPP: 0,
  lastSentAt: "",
};
