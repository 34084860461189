export const NavItems = ({
  router,
  DiscountsMajor,
  QuestionMarkMajor,
  ReportsMajor,
  CustomersMajor,
  BalanceMajor,
  ConfettiMajor,
}) => ({
  "analytics-overview": {
    url: "/analytics/overview",
    label: "Overview",
    selected:
      router.route === "/analytics/overview" || router.route === "/analytics",
  },
  "coupon-analytics": {
    url: "/analytics/coupon-analytics?page=1",
    label: "Coupon Analytics",
    selected: router.route === "/analytics/coupon-analytics",
  },
  "utm-analytics": {
    url: "/analytics/utm-analytics",
    label: "UTM Analytics",
    selected:
      router.route === "/analytics/utm-analytics" ||
      router.route === "/analytics/campaign",
  },
  // orders: {
  //   url: "/orders?page=1&filter=all",
  //   label: "All Orders",
  //   selected: router.route === "/orders" || router.route === "/order",
  // },
  orders: {
    url: "/orders?page=1&filter=all",
    label: "Orders",
    selected: router.route === "/orders" || router.route === "/order",
  },
  "abandoned-checkouts": {
    url: "/abandoned?page=1",
    label: "All Checkouts",
    selected:
      router.route === "/abandoned" || router.route === "/abandoned/[id]",
  },
  "abandoned-checkouts-via-number": {
    url: "/abandoned?page=1",
    label: "Abandoned Checkouts via number",
    selected:
      router.route === "/abandoned" || router.route === "/abandoned/[id]",
  },
  "abc-negative-skus": {
    url: "/settings/abandoned-cart-setting",
    label: "ABC/ Create Cart",
    selected: router.route === "/settings/abandoned-cart-setting",
  },
  "abc-sms-config": {
    url: "/abandoned-carts/sms-config",
    label: "Recover via SMS",
    selected: router.route === "/abandoned-carts/sms-config",
  },
  "abc-wa-config": {
    url: "/abandoned-carts/whatsapp-config",
    label: "Recover via WhatsApp",
    selected: router.route === "/abandoned-carts/whatsapp-config",
  },
  "converted-orders": {
    url: "/abandoned-carts/converted-orders?page=1",
    label: "Converted Orders",
    selected: router.route === "/abandoned-carts/converted-orders",
  },
  transactions: {
    url: "/upi/transactions?page=1&filter=all",
    label: "Transactions",
    selected: router.route === "/upi/transactions" || router.route === "/upi",
  },
  settlements: {
    url: "/upi/settlements?page=1&filter=all",
    label: "Settlements",
    selected:
      router.route === "/upi/settlements" ||
      router.route === "/upi" ||
      router.route === "/upi/settlements/transactions",
  },
  refunds: {
    url: "/upi/refunds?page=1&filter=all",
    label: "Refunds",
    selected: router.route === "/upi/refunds" || router.route === "/upi",
  },
  discounts: {
    label: "Discounts",
    icon: DiscountsMajor,
    url: "/discounts",
    // new: true,
    selected:
      router.route === "/discounts" ||
      router.route === "/discounts/new" ||
      router.route === "/discounts/[id]",
  },
  reports: {
    label: "Reports",
    icon: ReportsMajor,
    // new: true,
    url: "/reports",
    selected: router.route === "/reports",
  },
  // "payment-method": {
  //   url: "/settings/payments",
  //   label: "Payment Method",
  //   selected:
  //     router.route === "/settings/payments" || router.route === "/settings",
  // },
  "whatsapp-config": {
    label: "Whatsapp",
    // new: true,
    url: "/settings/whatsapp",
    selected: router.route === "/settings/whatsapp",
  },
  "sms-config": {
    label: "SMS Config",
    // new: true,
    url: "/settings/sms",
    selected: router.route === "/settings/sms",
  },
  cod: {
    url: "/settings/payments/cod",
    label: "COD Setup",
    selected:
      router.route === "/settings/payments/cod" || router.route === "/settings",
  },
  prepaid: {
    url: "/settings/payments/prepaid",
    label: "Prepaid Setup",
    selected:
      router.route === "/settings/payments/prepaid" ||
      router.route === "/settings",
  },
  "partial-payments": {
    url: "/settings/payments/partial-payments",
    label: "Partial Payments Setup",
    selected:
      router.route === "/settings/payments/partial-payments" ||
      router.route === "/settings",
  },
  "event-tracking": {
    url: "/settings/events",
    label: "Event Tracking",
    selected: router.route === "/settings/events",
  },
  "checkout-form": {
    url: "/settings/checkout-form",
    label: "Checkout UI",
    selected: router.route === "/settings/checkout-form",
  },
  wallet: {
    label: "Wallet",
    icon: BalanceMajor,
    selected: router.route === "/wallet",
    url: "/wallet",
  },
  // support: {
  //   label: "Support",
  //   icon: QuestionMarkMajor,
  //   selected: router.route === "/support",
  //   url: "/support",
  // },
  users: {
    label: "Users",
    icon: CustomersMajor,
    url: "/users",
    // new: true,
    selected:
      router.route === "/users" ||
      router.route === "/users/create-user" ||
      router.route === "/users/edit-user",
  },
  "shipping-providers": {
    label: "Shipping Providers",
    url: "/settings/shipping-providers",
    selected: router.route === "/settings/shipping-providers",
  },
  "express-shipping": {
    label: "Express Shipping",
    url: "/settings/express-shipping",
    selected: router.route === "/settings/express-shipping",
  },
  "business-info": {
    label: "Business Info",
    url: "/settings/business-info",
    selected: router.route === "/settings/business-info",
  },
  CODFIRM: {
    label: "CODFIRM",
    url: "",
    selected: router.route === "",
  },
});
