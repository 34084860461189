import {
  BalanceMajor,
  CustomersMajor,
  DiscountsMajor,
  QuestionMarkMajor,
  ReportsMajor,
} from "@shopify/polaris-icons";
import { NavItems } from "../constants/navItems";

export const filterPermissions = (permissions, mapperRoute, router) => {
  permissions.push("CODFIRM");    //Adding this to show CODFIRM option to all the users .
  let filteredOptions = permissions.filter((item) =>
    mapperRoute.includes(item)
  );
  filteredOptions.sort(function (a, b) {
    return mapperRoute.indexOf(a) - mapperRoute.indexOf(b);
  });

  filteredOptions = filteredOptions.map(
    (permission) =>
      NavItems({
        router,
        DiscountsMajor,
        QuestionMarkMajor,
        ReportsMajor,
        CustomersMajor,
        BalanceMajor,
      })[permission]
  );
  return filteredOptions;
};